import { flatten } from 'lodash';
import { getQuery } from 'client/utils/location';
import { Storage } from 'site-modules/shared/utils/storage';

export const SEM_CAMPAIGN_APPRAISALS_STORAGE_KEY = 'sem_campaign_appraisals';
export const SEM_CAMPAIGN_FACEBOOK_STORAGE_KEY = 'sem_campaign_facebook';

const UTM_ACCOUNT = {
  APPRAISALS: 'appraisals',
  APPRAISAL: 'appraisal',
  EVAL: 'eval',
};

const UTM_SOURCE = {
  FACEBOOK: 'facebook',
};

export const AppraisalUtmStorage = {
  get isSemCampaignAppraisals() {
    return this.getSessionStorage().get(SEM_CAMPAIGN_APPRAISALS_STORAGE_KEY) || false;
  },

  get isSemCampaignFacebook() {
    return this.getSessionStorage().get(SEM_CAMPAIGN_FACEBOOK_STORAGE_KEY) || false;
  },

  /**
   * Returns session storage instance
   * @returns {Storage}
   */
  getSessionStorage() {
    if (!this.sessionStorage) this.sessionStorage = new Storage('sessionStorage');
    return this.sessionStorage;
  },

  /**
   * Checks "utm_account" query parameter value to be {UTM_ACCOUNT.APPRAISALS} or {UTM_ACCOUNT.APPRAISAL} and sets sessions storage if meets business requirements.
   * @param {Object} location - pageContext.location
   */
  checkAndSetSemCampaignAppraisals(location) {
    if (this.isUtmAccountQueryParamAppraisals(location)) {
      this.getSessionStorage().set(SEM_CAMPAIGN_APPRAISALS_STORAGE_KEY, true);
    }
  },

  /**
   * Checks "utm_source" and "utm_account" query parameter values to be {UTM_SOURCE.FACEBOOK} or include {UTM_ACCOUNT.EVAL}, respectively, and sets sessions storage if meets business requirements.
   * @param {Object} location - pageContext.location
   */
  checkAndSetSemCampaignFacebook(location) {
    if (this.isUtmSourceQueryParamFacebook(location)) {
      this.getSessionStorage().set(SEM_CAMPAIGN_FACEBOOK_STORAGE_KEY, true);
    }
  },

  /**
   * Checks "utm_account" query parameter value to be {UTM_ACCOUNT.APPRAISALS} or {UTM_ACCOUNT.APPRAISAL}
   * @param {Object} location - pageContext.location
   * @returns {Boolean}
   */
  isUtmAccountQueryParamAppraisals(location) {
    try {
      const { utm_account: utmAccount } = getQuery(location);

      return flatten([utmAccount]).some(
        account =>
          account &&
          (UTM_ACCOUNT.APPRAISALS === account.toLowerCase() || UTM_ACCOUNT.APPRAISAL === account.toLowerCase())
      );
    } catch (e) {
      console.error('Error in isUtmAccountQueryParamAppraisals:', e.message); // eslint-disable-line no-console
      return false;
    }
  },

  /**
   * Checks "utm_source" and "utm_account" query parameter values to be {UTM_SOURCE.FACEBOOK} and include {UTM_ACCOUNT.EVAL}, respectively
   * @param {Object} location - pageContext.location
   * @returns {Boolean}
   */
  isUtmSourceQueryParamFacebook(location) {
    try {
      const { utm_account: utmAccount, utm_source: utmSource } = getQuery(location);

      const utmAccountOk = flatten([utmAccount]).some(account =>
        new RegExp(`\\b${UTM_ACCOUNT.EVAL}(_.*)?`, 'i').test(account)
      );

      const utmSourceOk = flatten([utmSource]).some(source => source && UTM_SOURCE.FACEBOOK === source.toLowerCase());

      return utmAccountOk && utmSourceOk;
    } catch (e) {
      console.error('Error in isUtmSourceQueryParamFacebook:', e.message); // eslint-disable-line no-console
      return false;
    }
  },
};
