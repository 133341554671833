import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID } from 'site-modules/shared/constants/appraisal/appraisal';

export class AppraisalTabLink extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    tabLink: PropTypes.shape({
      id: PropTypes.string,
      secondaryLinkLabel: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: 'text-primary-darker',
  };

  handleClick = event => {
    event.preventDefault();
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_START,
      event_data: {
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_name: TrackingConstant.ACTION_TOOL_SELECT,
        subaction_name: TrackingConstant.TAB_SELECT,
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        creative_id: APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID,
        value: this.props.tabLink.secondaryLinkLabel,
      },
    });
    this.props.onClick(event);
  };

  render() {
    const {
      tabLink: { id, secondaryLinkLabel },
      className,
    } = this.props;

    return (
      <a
        className={classnames('text-underline', className)}
        href="#appraisal-tabs-container"
        onClick={this.handleClick}
        data-tab-id={id}
        data-testid={id}
        rel="nofollow"
      >
        {secondaryLinkLabel}
      </a>
    );
  }
}
