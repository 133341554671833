import React from 'react';
import { useAppraisalTabsContext } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-context';
import { AppraiseByMmyTabContent } from './appraise-by-mmy-tab-content';

export const AppraiseByMmyTabContainer = props => {
  const { mmy, setMmy, setVin, isReadyForRedirect, setIsReadyForRedirect, setStyleIds } = useAppraisalTabsContext();

  const extendedProps = {
    ...props,
    mmy,
    setMmy,
    setVin,
    setStyleIds,
    isReadyForRedirect,
    setIsReadyForRedirect,
  };

  return <AppraiseByMmyTabContent {...extendedProps} />;
};
