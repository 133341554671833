import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Input, FormGroup } from 'reactstrap';
import { randomInt } from 'client/utils/seed-randomizers';
import {
  REQUIRED_VIN_LENGTH,
  VIN_HAS_INCORRECT_FORMAT,
  VIN_IS_NOT_LOCATED,
  VIN_HAS_INCORRECT_FORMAT_LENGTH,
} from 'client/site-modules/shared/constants/appraisal/appraisal';

export function VinTabInput({
  vinInputValue,
  onVinChange,
  isError,
  isVinValid,
  isVinValidLength,
  isSquishVinInvalid,
  isEval4351Chal,
}) {
  const uniqInputId = useRef(randomInt());

  const inputName = 'vin-number';
  const inputId = `${inputName}${uniqInputId.current}`;

  return (
    <FormGroup
      className={classnames('styled-input', {
        'has-danger': isError,
        'mb-0_5': isEval4351Chal,
      })}
    >
      <div className="input-wrapper pos-r">
        <Input
          name={inputName}
          id={inputId}
          type="text"
          className="form-control text-uppercase vin-number"
          value={vinInputValue}
          onChange={onVinChange}
          maxLength={REQUIRED_VIN_LENGTH}
          minLength={REQUIRED_VIN_LENGTH}
          placeholder="Enter VIN"
          aria-label="vin number"
          aria-required
          required
          {...(isError ? { 'aria-describedby': 'vinError', 'aria-invalid': true } : {})}
        />
      </div>
      {isError && (
        <div id="vinError" className="value-error-text text-danger small mt-0_5">
          {isVinValidLength && !isVinValid && (
            <div data-testid="vin-incorrect-format-error">{VIN_HAS_INCORRECT_FORMAT}</div>
          )}
          {!isVinValidLength && <div data-testid="vin-incorrect-length-error">{VIN_HAS_INCORRECT_FORMAT_LENGTH}</div>}
          {isSquishVinInvalid && <div data-testid="vin-not-located-error">{VIN_IS_NOT_LOCATED}</div>}
        </div>
      )}
    </FormGroup>
  );
}

VinTabInput.propTypes = {
  vinInputValue: PropTypes.string,
  onVinChange: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  isVinValid: PropTypes.bool,
  isVinValidLength: PropTypes.bool,
  isSquishVinInvalid: PropTypes.bool,
  isEval4351Chal: PropTypes.bool,
};

VinTabInput.defaultProps = {
  vinInputValue: '',
  isError: false,
  isVinValid: true,
  isVinValidLength: true,
  isSquishVinInvalid: false,
  isEval4351Chal: false,
};
