import React from 'react';
import { PARTNER_OFFER_ADDRESS_LOOKUP } from 'client/site-modules/shared/constants/appraisal/appraisal';
import { FindVinByAddress } from 'site-modules/shared/components/find-vin-by-address/find-vin-by-address';

export const AddressTab = props => (
  <div className="address-tab">
    <FindVinByAddress
      {...props}
      hasEscapeHatch={false}
      isAddressTab
      isLandingPage
      creativeId={PARTNER_OFFER_ADDRESS_LOOKUP}
    />
  </div>
);
