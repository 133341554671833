import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { EdmundsAPI } from 'client/data/api/api-client';

export const checkVinInventory = (vin, creativeId, cb) => {
  if (!vin) {
    return;
  }

  const inventoryAPIUrl = `/inventory/v5/vin/${vin}`;
  const options = {
    method: 'GET',
  };
  const trackingData = {
    event_type: TrackingConstant.EVENT_TYPE_ACTION_END,
    event_data: {
      action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
      creative_id: creativeId,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      subaction_name: TrackingConstant.SUBACTION_VIN_INVENTORY_CHECK,
    },
  };
  EdmundsAPI.fetch(inventoryAPIUrl, options)
    .then(() => {
      trackingData.event_data.value = TrackingConstant.VIN_FOUND;
      EventToolbox.fireTrackAction(trackingData);
      cb(true);
    })
    .catch(() => {
      trackingData.event_data.value = TrackingConstant.VIN_NOT_FOUND;
      EventToolbox.fireTrackAction(trackingData);
      cb(false);
    });
};
