import { isNil, isObject, isEqual, isEqualWith, isArray, size } from 'lodash';

const customizerById = (obj1, obj2) => {
  if (obj1?.id && obj2?.id) {
    return isEqual(obj1.id, obj2.id);
  }
  // 'undefined' means comparisons are handled by lodash.
  return undefined;
};

/**
 * Performs a deep comparison between two objects to determine if they are equivalent.
 * This method has custom comparison for objects with 'id' property, so it is faster than lodash.isEqual.
 * @param o1 first object to compare
 * @param o2 second object to compare
 * @returns `true` if the values are equivalent, else `false`.
 */
export function isEqualById(o1, o2) {
  return isEqualWith(o1, o2, customizerById);
}

const isNotComparableVal = val => isNil(val) || isObject(val);

/**
 * Compares two arrays by their IDs or other uniq field.
 *
 * @param {Array} arr1 - The first array to compare.
 * @param {Array} arr2 - The second array to compare.
 * @param {string} [idKey='id'] - The property name to identify elements by. Defaults to 'id'.
 *
 * @returns {boolean} Returns true if arr1 and arr2 contain objects with the same set of ids, otherwise false.
 */
export const isEqualArraysByIds = (arr1, arr2, idKey = 'id') => {
  if (!isArray(arr1) || !isArray(arr2)) return isEqual(arr1, arr2);
  if (size(arr1) !== size(arr2)) return false;

  const arr1Ids = arr1.map(obj => obj[idKey]);
  const arr2Ids = arr2.map(obj => obj[idKey]);

  if (arr1Ids.some(isNotComparableVal) || arr2Ids.some(isNotComparableVal)) return isEqual(arr1, arr2);

  return arr1Ids.sort().join(',') === arr2Ids.sort().join(',');
};
