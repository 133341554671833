import React from 'react';
import PropTypes from 'prop-types';
import { lazyLoad } from 'site-modules/shared/components/lazy-load/lazy-load';
import { AppraisalLazyLoadModalLoadingPlaceholder } from 'site-modules/shared/components/appraisal/appraisal-lazy-load-modal-loading-placeholder/appraisal-lazy-load-modal-loading-placeholder';

const LoadingComponent = ({ creativeId, toggleModal }) => (
  <AppraisalLazyLoadModalLoadingPlaceholder creativeId={creativeId} toggleModal={toggleModal} />
);

LoadingComponent.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  creativeId: PropTypes.string.isRequired,
};

export const FindVinModalContentAsync = lazyLoad({
  loader: () => import(/* webpackChunkName: "find-vin-modal-content" */ './find-vin-modal-content'),
  componentName: 'FindVinModalContent',
  loading: LoadingComponent,
});
