import dateFormat from 'dateformat';
import { capitalize } from 'lodash';
import { TrackingConstant } from 'client/tracking/constant';
import { TIME } from 'client/utils/time';
import { EventToolbox } from 'client/utils/event-toolbox';
import { getCurrentDate } from 'site-modules/shared/utils/date-utils';
import { DEFAULT_MILEAGE } from 'site-modules/shared/constants/appraisal/appraisal';
import { numberWithCommasV2 } from 'client/site-modules/shared/utils/string';

export const SELECT_DISTANCE_EVENT_DATA = creativeId => ({
  creative_id: creativeId,
  action_name: TrackingConstant.ACTION_RECEIVE_EPO,
  action_category: TrackingConstant.USER_ACTION_CATEGORY,
  action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
});

export const fireQuoteRecencyTrackingPixel = offerCreatedDateUtc => {
  if (offerCreatedDateUtc) {
    const currentDate = getCurrentDate().getTime();
    const createdDate = new Date(offerCreatedDateUtc).getTime();
    const numberOfFullDays = Math.floor((currentDate - createdDate) / TIME.MS.ONE_DAY);
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_END,
      event_data: {
        action_name: TrackingConstant.ACTION_RECEIVE_EPO,
        subaction_name: TrackingConstant.ACTION_QUOTE_RECENCY,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        creative_id: 'edm-entry-partner-offer',
        value: numberOfFullDays,
      },
    });
  }
};

export function getDefaultMileage(styleYear) {
  const currentDate = getCurrentDate();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0..11
  const twoMonthsAgo = currentMonth - 2;
  const monthCount = twoMonthsAgo > 0 ? twoMonthsAgo : 0;
  const yearAge = currentYear - styleYear;
  if (yearAge < 0 || (yearAge === 0 && monthCount === 0)) {
    return DEFAULT_MILEAGE.MIN;
  }
  return yearAge >= 11 ? 130000 : yearAge * 12000 + monthCount * 1000;
}

export function getMileageRangeMessage(year, make, model) {
  const defaultMileage = getDefaultMileage(year);
  const max = defaultMileage + DEFAULT_MILEAGE.THRESHOLD;
  const min =
    defaultMileage > DEFAULT_MILEAGE.THRESHOLD ? defaultMileage - DEFAULT_MILEAGE.THRESHOLD : DEFAULT_MILEAGE.MIN;
  return `In your area, the average mileage for a ${year} ${make} ${model} is between ${numberWithCommasV2(
    min
  )} - ${numberWithCommasV2(max)} miles.`;
}

export const getConditionPricingMetadata = ({ conditionPricing, condition }) => {
  const metadata = {};
  const hasConditionPricing =
    conditionPricing && Object.values(conditionPricing).every(conditionPrice => !!conditionPrice);

  if (hasConditionPricing) {
    Object.keys(conditionPricing).forEach(conditionPricingKey => {
      const conditionNiceName = capitalize(conditionPricingKey);
      const conditionPrice = `${conditionPricing[conditionPricingKey]}`;
      metadata[`edmunds-${conditionNiceName}`] = conditionPrice;
      if (conditionNiceName === condition) {
        metadata['edmunds-Final-TMV-Value'] = conditionPrice;
      }
    });
  }

  return metadata;
};

/**
 * Format the expired date display to match our UI/UX if it's formatted differently
 * @param {String} offerExpiredDate
 * @returns {String}
 */
export const getExpiresDisplayDate = expiresDisplayDate => {
  try {
    return dateFormat(expiresDisplayDate, 'mm/dd/yyyy');
  } catch (e) {
    // we should NOT expect a value that can be parsed as a date from the "expiresDisplayDate" field.
    return expiresDisplayDate;
  }
};
